import React from 'react';
import i18n from '../translate/i18n';

const Banner = () => {
    const translate = i18n
    return (
        <div class="container">
            <div class="row d-flex justify-content-center ">
                <div class="col-lg-5 s_col_no_bgcolor pt16 pb16">
                    <div class="card text-bg-white h-100 shadow">
                        <img class="card-img-top" src="/web/image/website.s_three_columns_default_image_1" alt="" loading="lazy" />
                        <div class="card-body">
                            <h3 class="card-title" align="center">
                                {translate.t("labels.My_Projects")}
                            </h3>
                            <p class="card-text">
                                {translate.t("labels.projects")}
                            </p>
                            <p align="center"><a href="https://github.com/heberthsilva" class="btn btn-primary">{translate.t("labels.detail")}</a></p>
                        </div>
                    </div>
                </div>
                {/* <div class="col-lg-4 s_col_no_bgcolor pt16 pb16">
                    <div class="card text-bg-white h-100 shadow" >
                        <img class="card-img-top" src="/web/image/website.s_three_columns_default_image_2" alt="" loading="lazy" />
                        <div class="card-body">
                            <h3 class="card-title">
                                My work
                            </h3>
                            <p class="card-text">
                                Here is a selection of my works, exhibited in my studio.
                            </p>
                            <p><a href="#" class="btn btn-primary">Discover</a></p>
                        </div>
                    </div>
                </div> */}
                <div class="col-lg-5 s_col_no_bgcolor pt16 pb16">
                    <div class="card text-bg-white h-100 shadow" >
                        <img class="card-img-top" src="/web/image/website.s_three_columns_default_image_3" alt="" loading="lazy" />
                        <div class="card-body">
                            <h3 class="card-title" align="center">
                            {translate.t("titles.Contato")}
                            </h3>
                            <p class="card-text">
                            {translate.t("titles.contact_call")}
                            </p>
                            <p align="center"><a href="mailto:heberth.silva@live.com" class="btn btn-primary">Contact me</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default Banner;
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {messages} from './languages';

i18n
    .use(LanguageDetector)
    .init({
        debug: false,
        defaultNS:['translations'],
        fallbackLng: 'pt',
        ns:['translations'],
        resources: messages
    })
    export default i18n;
// i18n.use(initReactI18next).init({
//     compatibilityJSON: 'v3',
//     lng: 'en',
//     resources: {
//         en: en,
//         pt: pt
//     },
//     react: {
//         useSuspense: false,
//     },
//     interpolation: {
//         escapeValue: false,
//     }
// })
// export default i18n;


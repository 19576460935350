import React from 'react';

const Contato = () => {
    return (
        <div>
            <h1>Contato</h1>
            <a href="/">Home</a>
            <br />
        </div>
    );
}

export default Contato;
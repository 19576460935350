import React from 'react';
import i18n from '../translate/i18n';
import heb from '../Components/icons/Heberth.svg'
const Home = () => {
const translate = i18n
    return (
        <div class="section hero">
            <div class="w-container">
                <div class="row align-items-center">
                <div class="col-lg-6 pt16 pb16">
                    <h2>Heberth Silva</h2>
                    <br></br>
                    <p>{translate.t("messages.Resumo")}</p>
                    <p><a href={translate.t("buttons.cv_link")} class="btn btn-secondary">{translate.t("buttons.cv")}</a></p>
                </div>
                <div class="pt16 pb16 col-lg-5 offset-lg-1 o_draggable o_colored_level">
                    <img src={heb} alt="" class="img img-fluid mx-auto o_we_custom_image o_modified_image_to_save" data-shape="web_editor/geometric/geo_square_2" data-shape-colors=";;;;" data-mimetype="image/svg+xml" data-original-id="262" data-original-src="/web/image/262-7fb86ce5/1614864738755.jfif" data-mimetype-before-conversion="image/jpeg" data-original-mimetype="image/webp" data-resize-width="690" data-file-name="1614864738755.svg"></img>
                </div>
                </div>
                <br></br>
                <br></br>
                <h1 class="small-heading">{translate.t("messages.Algo_Novo")}</h1>
                <p class="subtitle">{translate.t("messages.Juntos")}</p>
            </div>
        </div>                
    );
}

export default Home;
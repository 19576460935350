import './Components/Style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from './translate/i18n'
import eua from './Components/icons/eua.png'
import braz from './Components/icons/brasil.png'
import Footer from './Screens/Footer';
import Routes from './Routes';

const I18N_STORAGE_KEY = 'i18nextLng'

function App() {
  const translate = i18n;
  const buttonClick = (lang) => {
    localStorage.setItem(
      I18N_STORAGE_KEY,
      lang
    )
    window.location = window.location
  }

  return (
    <div className="Container">
      <head>
        <meta charset="utf-8" />
        <title>Heberth Silva Portifolio</title>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="Webflow" name="generator" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" crossorigin="anonymous" />
        <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js" type="text/javascript"></script>
        <link href="./Components/icons/header.ico"
          rel="shortcut icon" type="image/x-icon" />
        <link href="./Components/icons/header_alt.ico"
          rel="apple-touch-icon" />
      </head>
      <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease"
        class="navbar w-nav">
        <div class="w-container">
          <nav role="navigation" class="nav-menu w-clearfix w-nav-menu">
            <a href="home" aria-current="page" class="nav-link w-nav-link white">{translate.t('titles.Inicio')}</a>
            <a href="https://github.com/heberthsilva" class="nav-link w-nav-link white">{translate.t("titles.Projetos")}</a>
            <a href="contato" class="nav-link w-nav-link white">{translate.t("titles.Contato")}</a>
          </nav>
          <div class="menu-button w-nav-button">
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
        <button type="button" class='language-button' onClick={() => buttonClick('pt-BR')}> <img src={braz} alt="Brazil Flag" height="40" width="40" /></button>
        <button type="button" class='language-button' onClick={() => buttonClick('en-US')}> <img src={eua} alt="EUA Flag" height="40" width="40" /></button>

      </div>

      <Routes/>
      <Footer />

      <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=651eac0c561c984caf3e86cb"
        type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
        crossorigin="anonymous"></script>
      <script src="./Components/Terminal.js" type="text/javascript"></script>

    </div>

  );
}

export default App;

import React from 'react';
import i18n from '../translate/i18n';

const Skills = () => {
    const translate = i18n
    return (
        <div class="container">
            <h1 align="center">{translate.t("titles.Skill")}</h1>
            <h4 align="center">Backend</h4>
            <div class="row d-flex justify-content-center ">
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                C# (.NET/.NET Framework)
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                Java
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                PHP
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                Node
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                NEST JS
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                TypeScript
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="66" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.medio")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <h4 align="center" class="mt-4">Frontend</h4>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                HTML 5
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                CSS 3
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                JavaScript
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                Angular 9
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                                React
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               React Native
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <h4 align="center" class="mt-4">Data Base</h4>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               MySQL
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               SQL
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               Oracle
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="66" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.medio")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               Postgress
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="66" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.medio")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               MariaDB
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               MongoDB
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <h4 align="center" class="mt-4">Tools</h4>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               Docker
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="33" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.basico")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               AWS
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="66" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.medio")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               Azure
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="66" class="slider-middle" id="myRange"/> 
                                <h7>{translate.t("levels.medio")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <h4 align="center" class="mt-4">Agil</h4>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               Scrum
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               Kambam
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 s_col_no_bgcolor pt16 pb16 mt-4">
                    <div class="card text-bg-white  ">
                        <div class="card-body">
                            <h5 class="card-title" align="center">
                               ITIL
                            </h5>
                            <div class="slidecontainer " align="center" >                                
                                <input type="range" value="100" class="slider" id="myRange"/> 
                                <h7>{translate.t("levels.avancado")} </h7>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default Skills;
import Sobre from './Screens/Sobre';
import Projetos from './Screens/Projetos';
import Home from './Screens/Home';
import Banner from './Screens/Banner';
import Contato from './Screens/Contato';
import Skills from './Screens/Skills';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

const Routes = () => (

    <BrowserRouter>
        <Switch>
            <Route path="/" exact>
                <div>
                    <Home />
                    <Banner />
                    <Skills />
                </div>
            </Route>
            <Route path="/home">
                <div>
                    <Home />
                    <Banner />
                    <Skills />
                </div>
            </Route>
            <Route exact path="/sobre">
                <Sobre />
            </Route>
            <Route path="/projetos">
                <Projetos />
            </Route>
            <Route path="/contato">
                <Contato />
            </Route>
        </Switch>
    </BrowserRouter>


);
export default Routes;

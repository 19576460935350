
import React,{Component} from 'react';
import i18n from '../translate/i18n';

class Footer extends Component{

    constructor(props){
        super(props);
        this.state = {};
    }


    render(){
      const translate = i18n
        return(

            <div class="pt-3 my-md-5 pt-md-5 border-top">
            <div class="row">
              <div class="col-12 col-md">
                {/* <img class="mb-2" src="../../assets/brand/bootstrap-solid.svg" alt="" width="24" height="24"/> */}
                <small class="d-block mb-3 text-muted">&copy; 2023-2023 - Heberth Silva</small>
              </div>
              {/* <div class="col-6 col-md">
                <h5>Features</h5>
                <ul class="list-unstyled text-small">
                  <li><a class="text-muted" href="/">Algo legal</a></li>
                  <li><a class="text-muted" href="/">Feature aleatória</a></li>
                  <li><a class="text-muted" href="/">Recursos para times</a></li>
                  <li><a class="text-muted" href="/">Coisas para desenvolvedores</a></li>
                  <li><a class="text-muted" href="/">Outra coisa legal</a></li>
                  <li><a class="text-muted" href="/">Último item</a></li>
                </ul>
              </div> */}
              {/* <div class="col-6 col-md">
                <h5>Fontes</h5>
                <ul class="list-unstyled text-small">
                  <li><a class="text-muted" href="/">Fonte</a></li>
                  <li><a class="text-muted" href="/">Nome da fonte</a></li>
                  <li><a class="text-muted" href="/">Outra fonte</a></li>
                  <li><a class="text-muted" href="/">Fonte final</a></li>
                </ul>
              </div> */}
              <div class="col-lg-3 ">
                <h5>{translate.t("titles.Contato")}</h5>
                <ul class="list-unstyled text-small">
                  <li><a class="text-muted" href="tel:+5531997887263"><i class="fa fa-whatsapp" aria-hidden="true"></i> <i class="fa fa-phone" aria-hidden="true"></i> +55 (31) 9 9788-7263</a></li>
                  <li><a class="text-muted" href="mailto:heberth.silva@live.com"><i class="fa fa-envelope" aria-hidden="true"></i> heberth.silva@live.com</a></li>
                  <li><a class="text-muted" href="https://www.linkedin.com/in/heberthsilva/"><i class="fa fa-linkedin-square" aria-hidden="true"></i> in/heberthsilva/ </a></li> 
                  <li><a class="text-muted" href="https://github.com/heberthsilva"><i class="fa fa-github" aria-hidden="true"></i> git/heberthsilva </a></li> 
                             
                </ul>
              </div>
            </div>
          </div>

        );
    }
}

export default Footer;
const messages = {
    pt:{
        translations: {
            titles:{
                Inicio: 'Inicio',
                Sobre: 'Sobre',
                Projetos: 'Projetos',
                Contato: 'Contato',
                contact_call:'Não perca a oportunidade de conversarmos como podemos fazer um mundo melhor juntos.',
                Skill:"Minhas Habilidades"
            },
            messages:{
                Algo_Novo: 'Vamos fazer um mundo novo juntos',
                Juntos: 'Dediquem-se uns aos outros com amor fraternal. Prefiram dar honra aos outros mais do que a vocês. (Romanos 12:10) ',
                Resumo:'Desenvolvedor FullStack, majoritariamente Backend (C#, .NET, Node) altamente qualificado com anos de sólida experiência em projetos de desenvolvimento de software, apaixonado por tecnologia e conhecimento, buscando aprender cada dia mais com o mundo. Especialista na criação e otimização de aplicações e sistemas escaláveis e de alto desempenho. Capacidade comprovada de colaborar com equipes multidisciplinares para fornecer soluções inovadoras. Proficiente em múltiplas linguagens de programação e tecnologias de servidor. Responsável por gerenciar e liderar diversas equipes durante minha jornada! Experiência com sistemas ERP, SAP, RM, MASTERMAQ, Gestão de Redes, Financeiro, Fiscal, RH, Gestão de Folha de Pagamento, Hospitalar, Bilhetagem Eletrônica, Api’s, AWS, AZURE e buscando sempre melhorar esta lista!'
            },
            labels:{
                detail:'Mais Detalhes',
                My_Projects:'Meus Projetos',
                projects:'Se surpreenda com minhas implementações e como podemos melhorar juntos cada dia mais o seus projetos!'
            },
            buttons:
            {
                save: 'Salvar',
                language: 'Língua',
                cv:'Meu Currículo',
                cv_link:'https://drive.google.com/u/0/uc?id=13WboBnh_UjoWxt5rRR_q2wC95anSEPhL&export=download',
                
            },
            levels:{
                basico:'Basico',
                medio:'Intermediario',
                avancado:'Avançado',
            }             
        }
    }
}
export{messages}

const messages = {
    en:{
        translations: {
            titles:{
                Inicio: 'Home',
                Sobre: 'About',
                Projetos: 'Projects',
                Contato: 'Contact',
                contact_call:"Don't miss the opportunity to talk about how we can make a better world together.",
                Skill:"My Skill's"
                
            },
            messages:{
                Algo_Novo: 'Lets make a new World together',
                Juntos: 'Be devoted to one another in love. Honor one another above yourselves. (Romanos 12:10)',
                Resumo:'FullStack developer, mostly Backend (C#, .NET, Node) highly qualified with years of solid experience in software development projects, passionate about technology and knowledge, seeking to learn more and more from the world every day. Specialist in creating and optimizing scalable, high-performance applications and systems. Proven ability to collaborate with multidisciplinary teams to deliver innovative solutions. Proficient in multiple programming languages and server technologies. Responsible for managing and leading several teams during my journey! Experience with ERP, SAP, RM, MASTERMAQ, Network Management, Financial, Tax, HR, Payroll Management, Hospital, Electronic Ticketing, Api’s, AWS, AZURE systems and always seeking to improve this list!'
            },
            labels:{
                detail:'More Details',
                My_Projects:'My Projects',
                projects: 'Be surprised by my implementations and how we can improve your projects together every day!'
            },
            buttons:
            {
                save: 'Save',
                language: 'Language',
                cv:'My Resume',
                cv_link:'https://drive.google.com/u/0/uc?id=1xxKG3QTSLxdI7t9QxHeRGKIrEjw9Qjjg&export=download',
            },
            levels:{
                basico:'Basic',
                medio:'Intermediario',
                avancado:'Avançado',
            }           
        }
    }
}
export{messages}
